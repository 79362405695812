import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/**
 * User component to show it on a list
 * @param {object} template
 * @param {user} template
 * @param {func} setPlaceHolders
 * @param {func} setmessageValue
 * @param {func} setCurrentTemplate
 * @param {func} setshowTemplatesList
 * @param {func} setCurrentLevel
 * @param {string} agentName
 * @param {string} autocenter
 * @param {Int} currentLevel 
 * @param {func} setCurrentCategory
 * @param {func} setCurrentSubcategory
 * @param {string} integrationPhone
 * @param {string} autoCenterLandlinePhoneNumber
 * @returns 
 */
const TemplateItem = ({ template,
    setPlaceHolders,
    setmessageValue,
    setCurrentTemplate,
    setshowTemplatesList,
    currentLevel,
    setCurrentLevel,
    autoCenter,
    agentName,
    user,
    setCurrentCategory,
    setCurrentSubcategory,
    integrationPhone,
    autoCenterLandlinePhoneNumber
}) => {

    const [showCategoryArrow, setShowCategoryArrow] = useState(false);
    const [nameToShow, setNameToShow] = useState("");

    /**
     * Depending on the current level is showed the name,
     * the category or the subcategory
     */
    const getNameDependingOnLevel = () => {
        let showCategory = false;
        let nameToShow = template.finalName;

        if (currentLevel === 0) {
            showCategory = template.category !== "";
            nameToShow = showCategory ? template.category : template.finalName;
        } else if (currentLevel === 1) {
            showCategory = template.subcategory !== "";
            nameToShow = showCategory ? template.subcategory : template.finalName;
        }

        setShowCategoryArrow(showCategory);
        setNameToShow(nameToShow);
    }

    useEffect(() => {
        getNameDependingOnLevel();
    }, [currentLevel]);

    /**
     * Manage the selection depending on the level and the kind of template
     */
    const manageSelection = () => {
        getNameDependingOnLevel();
        if (currentLevel === 0) {
            manageSelectionLevelZero();
        } else if (currentLevel === 1) {
            manageSelectionLevelOne();
        } else {
            showMessageInTextArea();
        }
    }

    /**
     * If a template with no category is selected is showed in text area.
     * If a template with category is selected its templates or subcategories are showed
     */
    const manageSelectionLevelZero = () => {
        if (template.category !== "") {
            setCurrentLevel(1);
            setCurrentCategory(template.category);
        } else {
            showMessageInTextArea();
        }
    }
    /**
     * If a template with no subcategory is selected is showed in text area.
     * If a template with subcategory is selected its templates are showed
     */
    const manageSelectionLevelOne = () => {
        if (template.subcategory !== "") {
            setCurrentLevel(2);
            setCurrentSubcategory(template.subcategory);
        } else {
            showMessageInTextArea();
        }

    }

    /**
     * Show text on the textarea with placeholders replacements
     */
    const showMessageInTextArea = () => {
        let textReplaced = template.text;

        if (template.whatsapp) {
            let placeholderArray = getPlaceholdersArray(template.placeholder);
            setPlaceHolders(placeholderArray);
            textReplaced = addPlaceholdersName(textReplaced, placeholderArray);
            template.replacedText = textReplaced;
        } else {
            textReplaced = replacePlaceHolderOnMessage(textReplaced);
            setPlaceHolders(null);
        }

        setmessageValue(textReplaced);
        setCurrentTemplate(template);
        setshowTemplatesList(false);
        setCurrentLevel(0);
        setCurrentCategory("");
        setCurrentSubcategory("");
    };

    /**
     * Parse all the placeholders on the text templates
     * @param {string} text 
     */
    function replacePlaceHolderOnMessage(text) {
        const getFirstNameAgent = getFirstName(agentName);
        const autoCenterName = getAutoCenterName(autoCenter);
        let textReplaced = text.replace("{{username}}", user.name);
        textReplaced = textReplaced.replace("{{first_name}}", getFirstNameAgent);
        textReplaced = textReplaced.replace("{{town}}", autoCenterName);
        //This phone (phone_integration) is the mobile phone of the autocenter, the one use for whatsapp and sms
        textReplaced = textReplaced.replace("{{phone_integration}}", integrationPhone);
        //This phone (phone_landline) is the landline phone of the autocenter, the one use for calls
        if (autoCenterLandlinePhoneNumber) {
            textReplaced = textReplaced.replace("{{phone_landline}}", autoCenterLandlinePhoneNumber);
        }
        return textReplaced;
    }

    return (
        <div key={template.templateId} className="templatesItem"
            onClick={() => manageSelection()}>
            <li className="templateLi">{nameToShow}</li>
            <b><div className={showCategoryArrow ? "category" : "category hidden"}>&gt;</div></b>
        </div>
    );
};

/**
 * transform string with placeholders to array 
 * @param {string} list 
 * @returns 
 */
function getPlaceholdersArray(list) {

    list = list.replaceAll(",", "").replaceAll("''", "'");
    list = list.substring(1, list.length - 1);
    let placeholderArray = list.split("'");
    placeholderArray.pop();
    return placeholderArray;
}


/**
 * Replace placeholder numbers for numbers
 * @param {*} textReplaced 
 * @param {*} placeholderArray 
 * @returns 
 */
function addPlaceholdersName(textReplaced, placeholderArray) {
    let counter = 1;
    for (let placeholder of placeholderArray) {
        textReplaced = textReplaced.replace("{{" + counter + "}}", "{{" + placeholder + "}}");
        counter++;
    }
    // Hides ticketid placeholder
    textReplaced = textReplaced.replace("{{" + counter + "}}", "");
    return textReplaced;
}

/**
 * Function to return first name
 * @param {*} name 
 */
function getFirstName(name) {
    let nameArray = name.split(" ");
    return capitalize(nameArray[0]);
}

/**
 * Capitalize the first letter of the name
 * @param {*} word 
 */
function capitalize(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
}

/**
 * Returns the name of the autoCenter
 * @param {*} autoCenter 
 */
function getAutoCenterName(autoCenter) {
    if (autoCenter !== "" && autoCenter !== undefined && autoCenter !== null) {
        return (autoCenter.replace("Norauto ", "").split("_"))[0];
    }
    return "";
}


TemplateItem.propTypes = {
    template: PropTypes.object,
    user: PropTypes.object,
    setPlaceHolders: PropTypes.func,
    setmessageValue: PropTypes.func,
    setCurrentTemplate: PropTypes.func,
    setshowTemplatesList: PropTypes.func,
    setCurrentLevel: PropTypes.func,
    currentLevel: PropTypes.number,
    agentName: PropTypes.string,
    autoCenter: PropTypes.string,
    setCurrentCategory: PropTypes.func,
    setCurrentSubcategory: PropTypes.func,
    integrationPhone: PropTypes.string,
    autoCenterLandlinePhoneNumber: PropTypes.string
};


export default TemplateItem;