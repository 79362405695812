import React from "react";
import PropTypes from "prop-types";

/**
 * User component to show it on a list
 * @param {object} user 
 * @param {func} setuservalue 
 * @param {string} keyword 
 * @returns 
 */
const UserItem = ({ user, setuservalue, keyword }) => {


  const selectUser = () => {
    let phoneNumber = showPhoneNumber(keyword, user);
    if (user.identities) {
      setuservalue({
        name: user.name, phone: phoneNumber, id: user.id, previousName: user.name, previousPhone: phoneNumber, previousId: user.id, previousOptin: !user.user_fields.optout, optin: !user.user_fields.optout
      });
    }
  };
  return (
    <div className={`user-item ${user.identities ? "" : "withoutPhone"}`} onClick={selectUser}>
      <span className="avatar">{user.name[0].toUpperCase()}</span>
      <div className="userDetails">
        <p>{user.name}</p>
        <p>{showPhoneNumber(keyword, user)}</p>
      </div>
    </div>
  );
};

/**
 * Show phone number value
 * @param {string} keyword search string on the input box
 * @param {object} user object user founded on zendesk
 */
function showPhoneNumber(keyword, user) {
  if (!isNaN(keyword) && keyword !== "") {
    if (keyword.length > 0 && keyword.charAt(0) === "0") {
      keyword = keyword.substring(1);
    }
    let phones = user.identities.filter(identity => identity.value.replaceAll(" ", "").includes(keyword));
    if (phones.length > 0) {
      return phones[0].value.replaceAll(" ", "");
    }
  }

  return user.identities ? user.identities[0].value.replaceAll(" ", "") : "No phone";
}

UserItem.propTypes = {
  user: PropTypes.object,
  setuservalue: PropTypes.func,
  keyword: PropTypes.string
};


export default UserItem;