{
    "translation": {
        "requester": "Pesquisar cliente Português",
        "name": "Nome",
        "choose_customer": "Escolha o cliente",
        "phone": "Número de telefone",
        "autocenter": "Centro",
        "sms": "SMS",
        "cancel": "Cancelar",
        "whatsapp": "Whatsapp",
        "type_message": "Digite uma mensagem",
        "attach_file": "Anexar um arquivo",
        "apply_template": "+ Aplicar modelo",
        "apply_template_header": "Aplicar modelo",
        "send": "ENVIAR",
        "loading": "Carregando...",
        "not_authorized": "Você não está autorizado a usar este aplicativo.",
        "message_successfull": "Mensagem enviada com sucesso",
        "message_successfull_ticket": "Um ticket foi criado com id ",
        "error_sending": "Erro ao enviar a mensagem",
        "couldnt_verify": "Não foi possível verificar se o ticket foi enviado com sucesso",
        "couldnt_verify_ticket": "Você receberá quaisquer erros no ticket ",
        "no_phone_number": "É necessário um número de telefone com formato correto",
        "no_message": "A mensagem é obrigatória",
        "no_user": "O solicitante é obrigatório",
        "error_limit_files": "Você só pode anexar 5 arquivos, exclua um se quiser adicionar um novo",
        "accept": "Aceitar",
        "error_limit_size": "Os arquivos podem ter tamanho máximo de 50 MB",
        "error_uploading_file": "Erro de integração, não foi possível fazer upload do arquivo",
        "integration_error": "Erro de integração",
        "create": "Criar",
        "newrequester": "Nome do cliente",
        "new_client": "Novo cliente",
        "username_empty": "O nome do usuário é obrigatório",
        "phone_invalid": "O número de telefone do usuário parece inválido",
        "phone_empty": "O número de telefone do usuário é obrigatório",
        "api_error": "Erro ao criar o usuário, entre em contato com o administrador do Zendesk",
        "phone_invalid_country_code": "O número de telefone deve começar com <country code>",
        "send_new_message": "Enviar nova mensagem",
        "token_expired_message": "Sessão expirada. Por favor, saia desta tela e entre nela novamente.",
        "error_creating_ticket": "O ticket não foi criado.",
        "optin_required": "Para enviar Whatsapp é necessário que o cliente não tenha rejeitado o opt-in",
        "select_template": "Selecione o modelo",
        "error_empty_placholder": "Todos os espaços reservados são obrigatórios",
        "error_empty_attachment": "Para enviar Whatsapp é obrigatório anexar imagem",
        "error_invalid_extension": "Tipo de arquivo inválido"
    }
}