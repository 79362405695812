import React from "react";
import PropTypes from "prop-types";


/**
 * One attachment name 
 * @param {array} attachments : files attached to message
 * @param {func} setAttachments 
 * @param {object} attachment: file to show
 * @param {func} setLimitFiles
 * @returns 
 */
const AttachmentItem = (
    { attachments,
        setAttachments,
        attachment,
        setLimitFiles }) => {

    /**
     * If name is too long shorts it and add "..."
     * @param {String} name 
     * @returns 
     */
    const shortName = (name) => {
        let numberOfChars = 50;
        return name.length > numberOfChars ? name.substring(0, (numberOfChars - 2)) + "..." : name;
    };

    /**
     * add index to attachments and set them
     * @param {array} newAttachments 
     */
    const addIndexAndSet = (newAttachments) => {
        let count = 0;
        // Reorder index
        for (let attachment of newAttachments) {
            attachment.index = count;
            count++;
        }
        setAttachments(newAttachments);
    };

    /**
    * Remove file selected by user
    * @param {integer} index 
    */
    const removeFile = (index) => {
        setLimitFiles(false);
        const newAttachments = attachments.filter(attachment => attachment.index !== index);
        addIndexAndSet(newAttachments);
    };

    return (
        <div key={attachment.index} className="attachmentItem ">
            <div className="row">
                <div className="col-1 removeIcon" >
                    <b className="removeAttachment" onClick={() => removeFile(attachment.index)}>x</b>
                </div>
                <div className="col-10 attachmentName">
                    <li className="attachmentLi">{shortName(attachment.name)}</li>
                </div>
            </div>
        </div>
    );
};


AttachmentItem.propTypes = {
    attachments: PropTypes.array,
    setAttachments: PropTypes.func,
    attachment: PropTypes.object,
    setLimitFiles: PropTypes.func,
};

export default AttachmentItem;