{
    "translation": {
        "requester": "Cliente",
        "name": "Nombre",
        "choose_customer": "Seleccione un cliente",
        "phone": "Número de teléfono",
        "autocenter": "Autocenter",
        "sms": "SMS",
        "cancel": "Cancelar",
        "whatsapp": "Whatsapp",
        "type_message": "Escriba su mensaje",
        "attach_file": "Adjuntar un fichero",
        "apply_template": "+ Aplicar template",
        "apply_template_header": "Aplicar template",
        "send": "ENVIAR",
        "loading": "Cargando...",
        "not_authorized": "No está autorizado para utilizar esta app.",
        "integration_error": "Error de integración",
        "no_phone_number": "Es obligatorio un número de teléfono válido",
        "no_message": "El mensaje no puede estar vacío",
        "no_user": "Es necesario elegir un usuario",
        "error_limit_files": "Solo puedes adjuntar 5 archivos, borra uno si quieres añadir uno nuevo",
        "accept": "Aceptar",
        "error_limit_size": "Los archivos no pueden tener un tamaño superior a 50 MB",
        "error_uploading_file": "Error de integración, el archivo no pudo ser subido",
        "error_invalid_extension": "Tipo de archivo no válido",
        "create": "Crear",
        "newrequester": "Nombre del cliente",
        "new_client": "Nuevo cliente",
        "phone_invalid": "El telefono del usuario no parece ser válido",
        "phone_empty": "El telefono del usuario es obligatorio",
        "api_error": "Error creando el usuario, por favor contacte con su administrador de Zendesk",
        "message_successfull": "El mensaje se ha enviado correctamente",
        "message_successfull_ticket": "Se ha creado el ticket ",
        "couldnt_verify_ticket": "Recibirás cualquier error en el ticket ",
        "error_sending": "Error enviando el mensaje",
        "couldnt_verify": "No se pudo verificar si el mensaje fue enviado correctamente",
        "username_empty": "El nombre del cliente es obligatorio",
        "phone_invalid_country_code": "El número telefónico debe empezar con 00<código país>",
        "send_new_message": "Enviar nuevo mensaje",
        "token_expired_message": "Sesión expirada. Por favor, salga de esta pantalla y vuelva a entrar.",
        "error_creating_ticket": "No se pudo crear el ticket",
        "optin_required": "Para enviar Whatsapp es necesario seleccionar un cliente que no haya rechazado el opt-in",
        "select_template": "Seleccionar plantilla",
        "error_empty_placholder": "Todos los placeholders son requeridos",
        "error_empty_attachment": "Es obligatorio adjuntar una imagen"
    }
}