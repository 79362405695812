import React, { useEffect, useState } from "react";
import { getTemplates } from "../../services/dynamo";
import PropTypes from "prop-types";
import { IoMdArrowBack } from "react-icons/io";
import TemplateItem from "./templateItem";


/**
 * Show a list with al templates from API
 * @param {func} translation 
 * @param {string} showingValue : shows or hides this component
 * @param {func} setshowTemplatesList 
 * @param {string} token : Api validations
 * @param {string} apiKey : Api validations
 * @param {array} templates : templates from Data base
 * @param {func} setTemplates 
 * @param {func} setmessageValue
 * @param {object} user
 * @param {string} agentCountryCode
 * @param {func} setPlaceHolders
 * @param {func} setCurrentTemplate
 * @param {string} agentName
 * @param {string} autoCenter
 * @param {string} integrationPhone
 * @param {string} autoCenterLandlinePhoneNumber
 * @returns 
 */
const TemplatesList = ({ translation,
    showingValue,
    setshowTemplatesList,
    token,
    apiKey,
    templates,
    setTemplates,
    setmessageValue,
    user,
    agentCountryCode,
    agentName,
    setPlaceHolders,
    setCurrentTemplate,
    autoCenter,
    integrationPhone,
    autoCenterLandlinePhoneNumber
}) => {


    const [currentLevel, setCurrentLevel] = useState(0);
    const [currentCategory, setCurrentCategory] = useState("");
    const [currentSubcategory, setCurrentSubcategory] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            if (agentCountryCode) {
                const responseTemplates = await getTemplates(token, apiKey, agentCountryCode);
                addCategoriesToTemplateObject(responseTemplates);
                setTemplates(responseTemplates);
            }
        };
        fetchData();
    }, [agentCountryCode]);



    //There are up to three levels, depending on the level in which we are, 
    //the arrow back changes its behavior
    const manageArrowBack = () => {
        if (currentLevel === 0) {
            //Close the templates tab
            setshowTemplatesList(false);
            setCurrentCategory("");
            setCurrentSubcategory("");
        } else if (currentLevel === 1) {
            //Back to level 0
            setCurrentLevel(0);
            setCurrentCategory("");
        } else {
            //Back to level 1
            setCurrentLevel(1);
            setCurrentSubcategory("");
        }
    }

    //Categories shown in the webview
    const categoriesAlreadyShown = [];

    return (
        <div className={"templatesNameList " + showingValue}>
            <div className="templatesHeader">
                <IoMdArrowBack size={35} onClick={() => manageArrowBack()} color={"gray"} />
                <span id="templatesHeaderText">{translation("apply_template_header")}</span>
            </div>

            <ul className="templatesUl">
                {
                    templates.map(template => {

                        const objectLevel = {
                            currentLevel,
                            categoriesAlreadyShown,
                            template,
                            currentCategory,
                            currentSubcategory
                        }
                        if (mustTemplateBeShown(objectLevel)) {

                            if (currentLevel === 0 && template.category !== "") {
                                categoriesAlreadyShown.push(template.category);
                            } else if (template.subcategory !== "") {
                                categoriesAlreadyShown.push(template.subcategory);
                            }

                            return (
                                <TemplateItem
                                    template={template}
                                    setPlaceHolders={setPlaceHolders}
                                    setmessageValue={setmessageValue}
                                    setCurrentTemplate={setCurrentTemplate}
                                    setshowTemplatesList={setshowTemplatesList}
                                    currentLevel={currentLevel}
                                    setCurrentLevel={setCurrentLevel}
                                    user={user}
                                    agentName={agentName}
                                    autoCenter={autoCenter}
                                    setCurrentCategory={setCurrentCategory}
                                    setCurrentSubcategory={setCurrentSubcategory}
                                    integrationPhone={integrationPhone}
                                    key={template.templateId}
                                    autoCenterLandlinePhoneNumber={autoCenterLandlinePhoneNumber} />
                            );
                        }
                        return false;
                    })
                }

            </ul>
        </div>
    );
};


/**
 * Name templates can have category and subcategory, the category and
 * the subcategory are in the template name separated by "::" so with this function
 * we add the proppertie "final_name", "category" and "subcategory" 
 * Category and subcategory are not required
 * @param {array} templates 
 * @returns 
 */
function addCategoriesToTemplateObject(templates) {
    for (const template of templates) {
        const templateNameSplit = template.name.split("::");
        if (templateNameSplit.length === 3) {
            template.category = templateNameSplit[0];
            template.subcategory = templateNameSplit[1];
            template.finalName = templateNameSplit[2];
        } else if (templateNameSplit.length === 2) {
            template.category = templateNameSplit[0];
            template.subcategory = "";
            template.finalName = templateNameSplit[1];
        } else {
            template.category = "";
            template.subcategory = "";
            template.finalName = templateNameSplit[0];
        }
    }
}

/**
 * Returns true if the templata must be shown depending on the category selected
 * @param {*} template 
 * @param {*} currentLevel 
 * @param {*} currentCategory 
 * @param {*} currentSubcategory 
 * @param {*} categoriesAlreadyShown
 * @returns 
 */
function mustTemplateBeShown({ template, currentLevel, currentCategory, currentSubcategory, categoriesAlreadyShown }) {

    if (currentLevel === 0) {
        const categoryIndex = categoriesAlreadyShown.indexOf(template.category);
        return categoryIndex < 0;
    } else if (currentLevel === 1) {
        const categoryIndex = categoriesAlreadyShown.indexOf(template.subcategory);
        return template.category === currentCategory && categoryIndex < 0;
    }

    return template.category === currentCategory && template.subcategory === currentSubcategory;

}


TemplatesList.propTypes = {
    translation: PropTypes.func,
    showingValue: PropTypes.string,
    setshowTemplatesList: PropTypes.func,
    token: PropTypes.string,
    apiKey: PropTypes.string,
    templates: PropTypes.array,
    setTemplates: PropTypes.func,
    setmessageValue: PropTypes.func,
    user: PropTypes.object,
    agentCountryCode: PropTypes.string,
    setPlaceHolders: PropTypes.func,
    setCurrentTemplate: PropTypes.func,
    agentName: PropTypes.string,
    autoCenter: PropTypes.string,
    integrationPhone: PropTypes.string,
    autoCenterLandlinePhoneNumber: PropTypes.string
};

export default TemplatesList;