{
        "translation": {
                "requester": "Search customer",
                "name": "Name",
                "choose_customer": "Choose customer",
                "phone": "Phone Number",
                "autocenter": "Autocenter",
                "sms": "SMS",
                "cancel": "Cancel",
                "whatsapp": "Whatsapp",
                "type_message": "Type a message",
                "attach_file": "Attach a file",
                "apply_template": "+ Apply template",
                "apply_template_header": "Apply template",
                "send": "SEND",
                "loading": "Laoding...",
                "not_authorized": "You are not authorized to use this app.",
                "message_successfull": "Message sent succesfully",
                "message_successfull_ticket": "A ticket was created with id ",
                "error_sending": "Error while sending the message",
                "couldnt_verify": "We couldn't verify if your ticket was sent successfully",
                "couldnt_verify_ticket": "You will receive any errors on ticket ",
                "no_phone_number": "A phone number with correct format is required",
                "no_message": "Message is required",
                "no_user": "Requester is required",
                "error_limit_files": "You can only attach 5 files, delete one if you want to add a new one",
                "accept": "Accept",
                "error_limit_size": "Files can have a maximum size of 50 MB",
                "error_uploading_file": "Integration error, file could not be uplooaded",
                "integration_error": "Integration error",
                "create": "Create",
                "newrequester": "Client name",
                "new_client": "New Client",
                "username_empty": "The user's name is mandatory",
                "phone_invalid": "The user's phone number seems invalid",
                "phone_empty": "The user's phone number is mandatory",
                "api_error": "Error when creating the user, please contact your Zendesk admin",
                "phone_invalid_country_code": "Phone number should start with 00<country code>",
                "send_new_message": "Send new message",
                "token_expired_message": "Session expired. Please leave this screen and enter to it again.",
                "error_creating_ticket": "Ticket was not created.",
                "optin_required": "To send whatsapp it is required that the client has not rejected the opt-in",
                "select_template": "Select template",
                "error_empty_placholder": "All placeholders are required",
                "error_empty_attachment": "To send Whatsapp it is mandatory to attach an image",
                "error_invalid_extension": "Invalid file type"
        }
}