
/**
 * Check if a number phone has a correct format depending of its country code
 * @param {String} countryCode
 * @param {String} phone
 * @param {String} event
 */
function checkNumberFormat(countryCode, phone, event = null) {
    // Check if phone has characters that are not numbers
    if (isNaN(phone) || phone.includes("e")) {
        return false;
    }

    switch (countryCode) {
        case "ES":
            return isValidSpainPhone(phone, event);
        case "FR":
            return isValidFrancePhone(phone, event);
        case "PT":
            return isValidPortugalPhone(phone, event);
        case "IT":
            return isValidItalyPhone(phone, event);
        case "BE":
            return isValidBelgiumPhone(phone, event);
        case "DE":
            return isValidGermanyPhone(phone, event);
        case "AT":
            return isValidAustriaPhone(phone, event);
        default:
            return false;
    }
}

/**
 * Returns true if is a correct format in Spain
 * @param {string} phone 
 * @param {string} event
 * @returns {boolean}
 */
function isValidSpainPhone(phone, event) {
    let formatRegex = new RegExp("^[6-9]");
    if (event === "search") {
        return phone.length <= 9 && formatRegex.test(phone);
    }
    return phone.length === 9 && formatRegex.test(phone);
}

/**
 * Returns true if is a correct format in France
 * @param {String} phone 
 * @param {String} event
 * @returns {Boolean}
 */
function isValidFrancePhone(phone, event) {
    let isNineDigitPhoneValid;
    let isTenDigitPhoneValid;
    if (event === "search") {
        isNineDigitPhoneValid = phone.length <= 9 && (phone.charAt(0) === "6" || phone.charAt(0) === "7");
        isTenDigitPhoneValid = phone.length <= 10 && phone.charAt(0) === "0" && (phone.charAt(1) === "6" || phone.charAt(1) === "7");
    } else {
        isNineDigitPhoneValid = phone.length === 9 && (phone.charAt(0) === "6" || phone.charAt(0) === "7");
        isTenDigitPhoneValid = phone.length === 10 && phone.charAt(0) === "0" && (phone.charAt(1) === "6" || phone.charAt(1) === "7");
    }
    return isNineDigitPhoneValid || isTenDigitPhoneValid;
}

/**
 * Returns true if is a correct format in Portugal
 * @param {String} phone 
 * @param {String} event
 * @returns {Boolean}
 */
function isValidPortugalPhone(phone, event) {
    let formatRegex = new RegExp("^9");
    if (event === "search") {
        return phone.length <= 9 && formatRegex.test(phone);
    }
    return phone.length === 9 && formatRegex.test(phone);
}

/**
 * Returns true if is a correct format in Italy
 * @param {String} phone 
 * @param {String} event
 * @returns {Boolean}
 */
function isValidItalyPhone(phone, event) {
    if (event === "search") {
        return isValidSearchItaly(phone);
    }
    const startsPhoneWithSpecificCode = phone.charAt(0) === "0" && phone.charAt(1) !== "0" && phone.charAt(1) !== "3";
    const isTenDigitValidPhone = phone.length === 10 && ((startsPhoneWithSpecificCode) || phone.charAt(0) === "3");
    const isElevenDigitValidPhone = phone.length === 11 && phone.substring(0, 2) === "03";
    return isTenDigitValidPhone || isElevenDigitValidPhone;
}

/**
 * Check validations for searching number for Spain
 * @param {String} phone 
 * @param {String} event
 * @returns {Boolean}
 */
function isValidSearchItaly(phone) {
    const phoneStartsWithThree = phone.startsWith("3");
    const phoneStartsWithZeroThree = phone.startsWith("03");

    if (phone.length === 1) {
        return phone.startsWith("0") || phoneStartsWithThree;
    } else if (phone.length === 2) {
        return phoneStartsWithZeroThree || phoneStartsWithThree;
    } else {
        return phone.length <= 11 && (phoneStartsWithZeroThree || phoneStartsWithThree);
    }
}

/**
 * Returns true if is a correct format in Belgium
 * @param {String} phone 
 * @param {String} event
 * @returns {Boolean}
 */
function isValidBelgiumPhone(phone, event) {
    if (event === "search") {
        if (phone.length === 1) {
            return phone.startsWith("4") || phone.startsWith("0");
        } else {
            return phone.length <= 10 && (phone.startsWith("4") || phone.startsWith("04"));
        }
    }
    return (phone.length === 10 && phone.substring(0, 2) === "04") ||
        (phone.length === 9 && phone.charAt(0) === "4");
}

/**
 * Returns true if is a correct format in Germany
 * @param {String} phone 
 * @param {String} event
 * @returns {Boolean}
 */
function isValidGermanyPhone(phone, event) {
    if (event === "search") {
        return phone.length <= 13;
    }
    return phone.length === 13;
}

/**
 * Returns true if is a correct format in Austria
 * @param {String} phone 
 * @param {String} event
 * @returns {Boolean}
 */
function isValidAustriaPhone(phone, event) {
    if (event === "search") {
        return phone.length <= 12;
    }
    return phone.length <= 12 && phone.length >= 3;
}


/**
 * Delete the international prefix of a phone number
 * @param {*} countryCode 
 * @param {*} phone 
 * @returns 
 */
function deletePrefix(countryCode, phone) {
    switch (countryCode) {
        case "ES":
            return deleteSpanishFrenchBelgianItalianPrefix(phone, 34);
        case "FR":
            let noPrefixPhone = deleteSpanishFrenchBelgianItalianPrefix(phone, 33);
            if (noPrefixPhone.charAt(0) === "0") {
                return noPrefixPhone.substring(1);
            }
            return noPrefixPhone;
        case "PT":
            return deletePortugalPrefix(phone);
        case "IT":
            return deleteSpanishFrenchBelgianItalianPrefix(phone, 39);
        case "BE":
            return deleteSpanishFrenchBelgianItalianPrefix(phone, 32);
        case "DE":
            return deleteGermanyPrefix(phone);
        case "AT":
            return deleteAustriayPrefix(phone);
        default:
            return phone;
    }
}



/**
 * Delete the international prefix of a Spanish,
 * Belgian, French or Italian phone number
 * @param {*} phone 
 * @param {*} prefix 
 * @returns 
 */
function deleteSpanishFrenchBelgianItalianPrefix(phone, prefix) {
    let regex;
    if (phone.length === 1) {
        regex = /^(0|3)/g;
    } else if (phone.length === 2) {
        regex = RegExp("^(00|" + prefix + ")");
    } else if (phone.length === 3) {
        regex = RegExp("^(003|" + prefix + ")");
    } else {
        regex = RegExp("^(00" + prefix + "|" + prefix + ")");
    }
    return phone.replace(regex, '').toString();
}


/**
 * Delete the international prefix of a Portuguese phone number
 * @param {*} phone 
 * @returns 
 */
function deletePortugalPrefix(phone) {
    let regex;
    if (phone.length === 1) {
        regex = /^(0|3)/g;
    } else if (phone.length === 2) {
        regex = /^(00|35)/g;
    } else if (phone.length === 3) {
        regex = /^(003|351)/g;
    } else if (phone.length === 4) {
        regex = /^(0035|351)/g;
    } else {
        regex = /^(00351|351)/g;
    }
    return phone.replace(regex, '').toString();
}


/**
 * Delete the international prefix of a German phone number
 * @param {*} phone 
 * @returns 
 */
function deleteGermanyPrefix(phone) {
    const regex = /^(0049)/g;
    return phone.replace(regex, '').toString();
}

/**
 * Delete the international prefix of a Austria phone number
 * @param {*} phone 
 * @returns 
 */
function deleteAustriayPrefix(phone) {
    const regex = /^(0043)/g;
    return phone.replace(regex, '').toString();
}


export { checkNumberFormat, deletePrefix };