import axios from "axios";
import { config } from "../config";

/**
 * Search users with a query string
 * @param {*} token 
 * @param {*} xApiKey
 * @param {string} keyword
 */
async function searchUsers(token, xApiKey, keyword) {
  const configRequest = {
    method: "get",
    url: `https://${sessionStorage.getItem("an")}.execute-api.eu-west-1.amazonaws.com/${sessionStorage.getItem("st")}/webview/users/search?query=${keyword}`,
    headers: {
      "Authorization": `Basic ${token}`,
      "x-api-key": xApiKey,
      "Content-Type": "application/json"
    }
  };
  try {
    return await axios(configRequest);
  } catch (error) {
    console.error(error);
    return null;
  }
}

/**
 * Create a new user
 * @param {string} token 
 * @param {string} xApiKey 
 * @param {object} userData
 */
async function createUser(token, xApiKey, userData) {
  const configRequest = {
    method: "post",
    url: `https://${sessionStorage.getItem("an")}.execute-api.eu-west-1.amazonaws.com/${sessionStorage.getItem("st")}/webview/users/create`,
    headers: {
      "Authorization": `Basic ${token}`,
      "x-api-key": xApiKey,
      "Content-Type": "application/json"
    },
    data: JSON.stringify(userData)
  };

  try {
    return await axios(configRequest);
  } catch (error) {
    console.error(error);
    return null;
  }
}


/**
 * Get Zendesk group data
 * @param {String} token 
 * @param {String} xApiKey 
 * @param {String} groupId
 * @param {String} userId
 */
async function getGroupWithUser(token, xApiKey, groupId, userId) {
  const query = `?groupId=${groupId}&userId=${userId}`;
  const configRequest = {
    method: "get",
    url: `https://${sessionStorage.getItem("an")}.execute-api.eu-west-1.amazonaws.com/${sessionStorage.getItem("st")}/webview/group${query}`,
    headers: {
      "Authorization": `Basic ${token}`,
      "x-api-key": xApiKey
    }
  };
  try {
    let groupResponse = await axios(configRequest);
    return {
      group: groupResponse.data.group,
      userName: groupResponse.data.user,
      integrationPhone: groupResponse.data.integrationPhone,
      landlinePhoneNumber: groupResponse.data.landlinePhoneNumber
    };
  } catch (error) {
    console.error(error);
    return { group: config.DEFAULT_GROUP, userName: "Norauto" };
  }
}


/**
 * create Ticket in zendesk and start sms sending
 * @param {String} token 
 * @param {String} xApiKey 
 * @param {Object} ticketData
 */
async function createTicket(token, xApiKey, ticketData) {
  let service = ticketData.templateName ? "proactivewhatsapp" : "proactivesms";
  const configRequest = {
    method: "post",
    url: `https://${sessionStorage.getItem("an")}.execute-api.eu-west-1.amazonaws.com/${sessionStorage.getItem("st")}/${service}`,
    headers: {
      "Authorization": `Basic ${token}`,
      "x-api-key": xApiKey,
      "Content-Type": "application/json"
    },
    data: JSON.stringify(ticketData)
  };

  try {
    let ticketResponse = await axios(configRequest);
    return ticketResponse.data.message;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export { searchUsers, createUser, getGroupWithUser, createTicket };