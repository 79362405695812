const axios = require("axios");
const DEFAULT_LANGUAGE = "EN";
/**
 * Get templates from Dynamo
 * @param {*} token 
 * @param {*} apiKey 
 * @returns 
 */
exports.getTemplates = async (token, apiKey, selectedCountryCode = DEFAULT_LANGUAGE) => {
    let url = `https://${sessionStorage.getItem("an")}.execute-api.eu-west-1.amazonaws.com/${sessionStorage.getItem("st")}/webview/templates?query=${selectedCountryCode}`;
    const headers = {
        headers: {
            "Authorization": "Basic " + token,
            "x-api-key": apiKey,
            "Content-Type": "application/json"
        }
    };
    try {
        const response = await axios.get(url, headers);
        return response.data.templates;
    } catch (error) {
        console.log(error);
        return [];
    }

};
