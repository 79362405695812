import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PlaceHolderItem from "./PlaceHolderItem";
import { config } from "../../config";

/**
 * Return a textbox list with with a textbox 
 * for each placeholder of the template
 * @param {Array} placeholders
 * @param {function} setMessageValue
 * @param {objet} currentTemplate
 * @param {function} setPlaceHoldersText
 * @param {objet} user
 * @returns 
 */
const PlaceHolderList = ({ placeholders, setMessageValue, currentTemplate, setPlaceHoldersText, user }) => {

    // Is an object with placeholders values using pairs of key-value
    // Values changes using values given by user in the text box
    const [placeholdersValues, setPlaceholdersValues] = useState({});

    /**
     * Changes placeholders in the template with values given by user
     */
    useEffect(() => {
        let newMessage = currentTemplate.replacedText;
        let placeholderText = [];
        for (let property in placeholdersValues) {
            if (placeholdersValues[property] !== "") {
                newMessage = newMessage.replace("{{" + property + "}}", placeholdersValues[property]);
            }
            placeholderText.push(placeholdersValues[property]);
        }
        setPlaceHoldersText([...placeholderText]);
        setMessageValue(newMessage);
    }, [placeholdersValues]);

    /**
     * When selected templated change, we change the placeholders map
     */
    useEffect(() => {
        let placeHoldersMap = {};
        for (let placeholder of placeholders) {
            if (isRequesterNamePlaceholder(placeholder)) {
                placeHoldersMap[placeholder] = user.name;
            } else {
                placeHoldersMap[placeholder] = "";
            }

        }
        setPlaceholdersValues(placeHoldersMap);
    }, [placeholders]);

    return (
        <div id="placeHoldersList">
            <div className="templatePlaceHolder" >
                {
                    placeholders.map(placeholder => {
                        return (
                            <PlaceHolderItem placeHolderName={placeholder} key={placeholder}
                                placeholdersValues={placeholdersValues} setPlaceholdersValues={setPlaceholdersValues} />
                        );
                    })
                }
            </div>

        </div>
    );
};


/**
 * Returns true if the placeholder name is a requester name translation
 * @param {*} placeholder 
 * @returns 
 */
function isRequesterNamePlaceholder(placeholder) {
    return config.REQUESTER_NAME_PLACEHOLDER.findIndex((translation) => translation === placeholder) > -1;
}

PlaceHolderList.propTypes = {
    placeholders: PropTypes.array,
    setMessageValue: PropTypes.func,
    currentTemplate: PropTypes.object,
    setPlaceHoldersText: PropTypes.func,
    user: PropTypes.object,
};

export default PlaceHolderList;