{
    "translation": {
        "requester": "Trouver un client",
        "name": "Nom",
        "choose_customer": "Sélectionner un client",
        "phone": "N° de téléphone",
        "autocenter": "Centre Auto",
        "sms": "SMS",
        "cancel": "Annuler",
        "whatsapp": "Whatsapp",
        "type_message": "Ecrire un message",
        "attach_file": "Joindre un fichier",
        "apply_template": "+ Appliquer un modèle",
        "apply_template_header": "Appliquer un modèle",
        "send": "Envoyer",
        "loading": "En cours de chargement",
        "not_authorized": "Vous n'êtes pas autorisé à utiliser cette application.",
        "message_successfull": "Message envoyé avec succès",
        "message_successfull_ticket": "Un ticket a été créé avec id ",
        "error_sending": "Erreur à l'envoi du message",
        "couldnt_verify": "Nous ne savons pas si le message a été envoyé correctement",
        "couldnt_verify_ticket": "Vous recevrez toute erreur sur le ticket ",
        "no_phone_number": "Le format du n° de téléphone est incorrect",
        "no_message": "Message obligatoire",
        "no_user": "Client obligatoire",
        "error_limit_files": "Vous pouvez joindre 5 fichiers maximum. Si vous souhaitez en ajouter un nouveau, merci d'en supprimer un",
        "accept": "Accepter",
        "error_limit_size": "Les fichiers ne peuvent pas dépasser 50 MB",
        "error_uploading_file": "Erreur d'intégration : le fichier n'a pas pu être téléchargé",
        "integration_error": "Erreur d'intégration",
        "create": "Créer",
        "newrequester": "Nom du client",
        "new_client": "Nouveau client",
        "username_empty": "Le nom du client est obligatoire",
        "phone_invalid": "Le n° de téléphone du client est incorrect",
        "phone_empty": "Le n° de téléphone du client est obligatoire",
        "api_error": "Erreur à la création d'un client. Veuillez contacter votre administrateur Zendesk",
        "phone_invalid_country_code": "Le nº de téléphone doit commencer par 00 <code pays>",
        "send_new_message": "Envoyer un nouveau message",
        "token_expired_message": "La session a expiré. Veuillez quitter cet écran et y accéder à nouveau.",
        "error_creating_ticket": "Le ticket n'a pas pu être créé.",
        "optin_required": "Pour envoyer Whatsapp, il est nécessaire de sélectionner un client qui n'a pas refusé l'opt-in.",
        "select_template": "Sélectionnez le modèle",
        "error_empty_placholder": "Tous les placeholders réservés sont obligatoires",
        "error_empty_attachment": "Il est obligatoire de joindre une image",
        "error_invalid_extension": "type de fichier invalide"
    }
}