import React from "react";
import PropTypes from "prop-types";
import AttachmentItem from "./AttachmentItem";

/**
 * List of attachments
 * @param {array} attachments : files attached to message
 * @param {func} setAttachments 
 * @param {func} setLimitFiles
 * @returns 
 */
const attachmentList = ({ attachments, setAttachments, setLimitFiles }) => {
    return (
        <ul className="attachmentsUl" id="attachmentList">
            {
                attachments.map(attachment => {
                    return (
                        <AttachmentItem key={attachment.index} attachments={attachments}
                            setAttachments={setAttachments} modal={false} attachment={attachment}
                            setLimitFiles={setLimitFiles} />
                    );
                })
            }
        </ul>
    );
};

attachmentList.propTypes = {
    attachments: PropTypes.array,
    setAttachments: PropTypes.func,
    setLimitFiles: PropTypes.func,
};

export default attachmentList;