import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { config } from "../config";
import { checkNumberFormat } from "../utilities/phoneNumbers";
import { createUser } from "../services/zendesk";
import { Inline } from "@zendeskgarden/react-loaders";
import { PALETTE } from "@zendeskgarden/react-theming";
import { IoMdArrowBack } from "react-icons/io";

/**
 * Component to show new user form
 * @param {object} param0
 * setShowUserCreationForm function to hide or show form
 * setUser function to set the user on the global state
 * token to call the api to create the user
 * selectedCountryCode
 * apiKey to call the api to create the user
 * setSelectedCountryCode function to set the country code on the global state
 */
export default function NewUserForm({ setShowUserCreationForm, setUser, token, apiKey, selectedCountryCode, setSelectedCountryCode }) {

    const { t } = useTranslation();
    const [userName, setUserName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errors, setErrors] = useState({ name: "", phone: "", api: "" });
    const [loaderCreate, setLoaderCreate] = useState(false);

    /**
     * It validates that data is correct and makes the call to the API to create the user
     */
    const createUserAction = async () => {
        const isNotEmptyUserName = userName.trim() !== "";
        const isNotEmptyPhoneNumber = phoneNumber !== "";
        const isValidPhone = checkNumberFormat(selectedCountryCode, phoneNumber);
        if (isNotEmptyUserName && isNotEmptyPhoneNumber && isValidPhone) {
            setLoaderCreate(true);
            let phoneNumberParsed = (+(phoneNumber.replace("+", "").replace(/ /g, ""))).toString();
            const fullPhoneNumber = `${config.COUNTRIES_LABELS[selectedCountryCode]}${phoneNumberParsed}`;
            const userData = { name: userName, phone: fullPhoneNumber };

            const user = await createUser(token, apiKey, userData);
            if (user) {
                setUser({
                    name: userName,
                    phone: fullPhoneNumber,
                    id: user.data.user.id,
                    optin: true,
                    previousOptin: true,
                    previousName: userName,
                    previousPhone: fullPhoneNumber,
                    previousId: user.data.user.id
                });
                setLoaderCreate(false);
                setShowUserCreationForm(false);
            } else {
                // should show a error message about can't be created by API
                setLoaderCreate(false);
            }
            setErrors({ name: "", api: t("api_error"), phone: "" });
        } else {
            const validations = {
                isNotEmptyUserName,
                isNotEmptyPhoneNumber,
                isValidPhone
            };
            const errorMessages = getErrorMessages(validations, t);
            setErrors({ name: "", api: "", phone: "", ...errorMessages });
        }
    };

    //If the user changes the country code, the phone number is reset
    useEffect(() => {
        setPhoneNumber("");
    }, [selectedCountryCode]);

    /**
     * Function to make validations on the phone number
     * @param {String} value 
     */
    function changePhone(value) {
        if (value.trim() === "") {
            setErrors({ ...errors, phone: "" });
        } else {
            const phoneValidation = checkNumberFormat(selectedCountryCode, value, "search");
            if (phoneValidation) {
                setErrors({ ...errors, phone: "" });
            } else {
                setErrors({ ...errors, phone: t("phone_invalid") });
            }
        }
        setPhoneNumber(value);
    }

    /**
     * Function to make validations on the user name
     * @param {String} value 
     */
    function changeUserName(value) {
        if (value.trim() !== "") {
            setErrors({ ...errors, name: "" });
        } else {
            setErrors({ ...errors, name: t("username_empty") });
        }
        setUserName(value);
    }

    return (
        <div className="createUser">
            <div className="templatesHeader">
                <IoMdArrowBack size={35} onClick={() => setShowUserCreationForm(false)} color={"gray"} />
                <span id="templatesHeaderText">{t("new_client")}</span>
            </div>
            <div className="newUserForm">
                {errors.api && <em className="errorText">{errors.api}</em>}
                <div className="userNameContainer">
                    <input
                        type="text"
                        className={`form-control customerSelect ${errors.name ? "errorField" : ""}`}
                        id="newRequester"
                        placeholder={t("newrequester")}
                        key="newRequester"
                        value={userName}
                        onChange={(event) => changeUserName(event.target.value)}
                    />
                    {errors.name && <em className="errorText">{errors.name}</em>}
                </div>

                <div className="phoneInputs">
                    <ReactFlagsSelect
                        selected={selectedCountryCode}
                        countries={config.COUNTRIES}
                        customLabels={config.COUNTRIES_LABELS}
                        placeholder=""
                        optionsSize={14}
                        className={`menu-flags ${errors.phone ? "errorFieldFlags" : ""}`}
                        fullWidth={false}
                        onSelect={countryCode => setSelectedCountryCode(countryCode)}
                    />
                    <input
                        type="tel"
                        className={`form-control phoneSelect ${errors.phone ? "errorField" : ""}`}
                        id="newPhone"
                        placeholder={t("phone")}
                        key="newphone"
                        value={phoneNumber}
                        onChange={(event) => changePhone(event.target.value)}
                    />
                    {errors.phone && <em className="errorText">{errors.phone}</em>}
                </div>
                <div className="buttonContainer">
                    {loaderCreate ?
                        <Inline size={32} color={PALETTE.blue[600]} className={"createLoader"} /> :
                        <button id="sendCreateUser" onClick={createUserAction}>{t("create")}</button>
                    }
                </div>
            </div>
        </div>
    );
}

/**
 * Returns an object with the messages
 * @param {object} validations 
 * @param {function} t 
 */
function getErrorMessages(validations, t) {

    let phoneMessage = validations.isNotEmptyPhoneNumber ? "" : t("phone_empty");
    if (phoneMessage === "") {
        phoneMessage = validations.isValidPhone ? "" : t("phone_invalid");
    }
    const userNameMessage = validations.isNotEmptyUserName ? "" : t("username_empty");

    return { phone: phoneMessage, name: userNameMessage };
}

NewUserForm.propTypes = {
    setShowUserCreationForm: PropTypes.func,
    setUser: PropTypes.func,
    token: PropTypes.string,
    apiKey: PropTypes.string,
    selectedCountryCode: PropTypes.string,
    setSelectedCountryCode: PropTypes.func
};