import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translations/en.json";
import translationBE from "./translations/be.json";
import translationIT from "./translations/it.json";
import translationES from "./translations/es.json";
import translationDE from "./translations/de.json";
import translationFR from "./translations/fr.json";
import translationPT from "./translations/pt.json";

const resources = {
  en: translationEN,
  es: translationES,
  de: translationDE,
  fr: translationFR,
  be: translationBE,
  pt: translationPT,
  it: translationIT
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;