import { config } from "../config";
import { checkNumberFormat } from "./phoneNumbers";

/**
 * Returns true if a template has media and has no attachments
 * @param {array} selectedFiles 
 * @param {object} currentTemplate 
 */
function hasFilledAttachmentWhenRequired(selectedFiles, currentTemplate) {
    return !currentTemplate.whatsapp || currentTemplate.media === "none" || selectedFiles.length > 0;
}

/**
 * Checks if channel selected is whatsapp and there are some empty placeholder
 * @param {Array} placeholdersText 
 * @param {String} channelValue
 * @returns 
 */
function hasNoEmptyPaceholder(placeholdersText, channelValue) {
    const emptyIndex = placeholdersText.findIndex(((placeholder) => placeholder === ""));
    return isSmsChannel(channelValue) || emptyIndex === -1;
}


/**
 * Generates error message for user depending on info added by user
 * @param {object} user
 * @param {string} selectedCountryCode
 * @param {string} formatPhone
 * @param {string} messageValue
 * @param {function} t
 * @param {array} placeholdersText
 * @param {string} channelValue
 * @param {Array} selectedFiles 
 * @param {object} currentTemplate 
 * @returns 
 */
function generateErrorMessage({ user, selectedCountryCode, formatPhone, messageValue, placeholdersText, channelValue, selectedFiles, currentTemplate }, t) {
    let tooltipMessage = "";
    if (!user || user.id === "") {
        tooltipMessage = `<p>- ${t("no_user")}</p>`;
    }
    let hasCountryCode = config.COUNTRIES.filter(country => user.phone.startsWith(config.COUNTRIES_LABELS[country]));
    if (!checkNumberFormat(selectedCountryCode, formatPhone) || hasCountryCode.length <= 0) {
        tooltipMessage = `${tooltipMessage}<p>- ${t("no_phone_number")}</p>`;
    }
    if (messageValue === "") {
        tooltipMessage = `${tooltipMessage}<p>- ${t("no_message")}</p>`;
    } else {
        if (!hasNoEmptyPaceholder(placeholdersText, channelValue)) {
            tooltipMessage = `${tooltipMessage}<p>- ${t("error_empty_placholder")}</p>`;
        }
        if (!hasFilledAttachmentWhenRequired(selectedFiles, currentTemplate)) {
            tooltipMessage = `${tooltipMessage}<p>- ${t("error_empty_attachment")}</p>`;
        }
    }
    return tooltipMessage;
}


/**
 * Returns true if channel value is sms os false if channel is not sms
 * @param {string} channelValue 
 * @returns boolean
 */
function isSmsChannel(channelValue) {
    return channelValue === config.CHANNEL_SMS;
}


/**
 * Returns true if sms is able to be sended
 * @param {*} messageValue 
 * @param {*} user 
 * @param {*} selectedCountryCode 
 * @param {*} formatPhone 
 * @param {*} placeholdersText 
 * @param {*} channelValue 
 * @param {*} selectedFiles 
 * @param {*} currentTemplate 
 * @returns boolean
 */
function isSMSAbleToBeSended({ messageValue, user, selectedCountryCode, formatPhone, placeholdersText, channelValue, selectedFiles, currentTemplate }) {
    let hasCountryCode = config.COUNTRIES.filter(country => user.phone.startsWith(config.COUNTRIES_LABELS[country]));
    const hasMessageValue = messageValue !== "";
    const isUserAssigned = user && user.id !== "";
    const hasPhoneNumberCorrectFormat = checkNumberFormat(selectedCountryCode, formatPhone) && hasCountryCode.length > 0;
    const isPlaceholderEmpty = hasNoEmptyPaceholder(placeholdersText, channelValue);
    const isFilledAttachmentWhenRequired = hasFilledAttachmentWhenRequired(selectedFiles, currentTemplate);
    return hasMessageValue && isUserAssigned && hasPhoneNumberCorrectFormat && isPlaceholderEmpty && isFilledAttachmentWhenRequired;
}

/**
 * Checks if modal should be hidden
 * @param {string} modalMessageClass
 * @param {object} element
 * @returns
 */
function shouldHideModal(modalMessageClass, element) {
    const closestElements = !element.closest("#modalLimitSize");
    return (modalMessageClass !== "hidden") && closestElements;
}

/**
 * Returns true if value is empty
 * @param {*} value 
 * @returns 
 */
function isEmpty(value) {
    return value === null || value === undefined || value === "" || value === false;
}

export { shouldHideModal, isSMSAbleToBeSended, isSmsChannel, generateErrorMessage, isEmpty };