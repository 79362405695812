import { config } from "../config";

/**
 * Get country from the URL transforming the language
 * @param {string} country
 * @returns {string}
 */
function getCountryFromURL(language){
    if(language.toUpperCase() === "UND"){
        return config.DEFAULT_LANGUAGE;
    }
    let country = language.toUpperCase().split("-");
    if(country.length > 1 && config.COUNTRIES.includes(country[1])){
        return country[1];
    }
    
    return config.DEFAULT_LANGUAGE;
}

export { getCountryFromURL };