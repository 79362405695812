import React from "react";
import PropTypes from "prop-types";
import { config } from "../config";

/**
 * Returns two radio buttons that toggle whatsapp and sms as channel
 * @param {function} translations
 * @param {boolean} activeOptin
 * @param {function} setChannelValue
 * @param {function} setModalMessageTitle
 * @param {function} setModalType
 * @param {function} setModalMessageDescription
 * @param {function} setModalMessageClass
 * @param {boolean} updatingAttachment
 * @param {boolean} smsChecked
 * @param {boolean} whatsappChecked
 * @returns 
 */
const ChannelSelector = ({
    translations,
    activeOptin,
    setChannelValue,
    setModalMessageTitle,
    setModalType,
    setModalMessageDescription,
    setModalMessageClass,
    updatingAttachment,
    smsChecked,
    whatsappChecked }) => {

    /**
     * Handler when whatsapp is selected as channel
     * If user has no optin or is updating a file
     * it shows an error message
     * @param {object} e 
     */
    const handlerWhatsapp = (e) => {
        if (!activeOptin && !updatingAttachment) {
            setModalMessageTitle(translations("optin_required"));
            setModalType("info");
            setModalMessageDescription("");
            setModalMessageClass("");
            e.preventDefault();
        } else if (!updatingAttachment) {
            setChannelValue(config.CHANNEL_WHATSAPP);
        } else {
            e.preventDefault();
        }
    };


    /**
     * Handler when sms is selected as channel
     * @param {Object} e 
     */
    const handlerSms = (e) => {
        if (!updatingAttachment) {
            setChannelValue(config.CHANNEL_SMS);
        } else {
            e.preventDefault();
        }

    };

    return (
        <div className="selectChannel" >
            <div className={updatingAttachment ? "disabled radioBtn" : "radioBtn"} >
                <input type="radio" id="sms" name="channel" value="sms"
                    onClick={(e) => handlerSms(e)} checked={smsChecked} />
                <label htmlFor="sms">{translations("sms")}</label>
            </div>
            <div className={!activeOptin || updatingAttachment ? "disabled radioBtn" : "radioBtn"} >
                <input type="radio" id="whatsapp" name="channel" value="whatsapp" checked={whatsappChecked} onClick={(e) => handlerWhatsapp(e)} />
                <label htmlFor="whatsapp">{translations("whatsapp")}</label>
            </div>
        </div>
    );
};

ChannelSelector.propTypes = {
    translations: PropTypes.func,
    activeOptin: PropTypes.bool,
    setChannelValue: PropTypes.func,
    setModalMessageTitle: PropTypes.func,
    setModalType: PropTypes.func,
    setModalMessageDescription: PropTypes.func,
    setModalMessageClass: PropTypes.func,
    updatingAttachment: PropTypes.bool,
    smsChecked: PropTypes.bool,
    whatsappChecked: PropTypes.bool,
};

export default ChannelSelector;