import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { config } from "./config";
import { jwtAuthentication } from "./services/jwtAuth";
import jwt_decode from "jwt-decode";

// Zendesk  Garden
import { ThemeProvider, PALETTE } from "@zendeskgarden/react-theming";
import { Inline } from "@zendeskgarden/react-loaders";
import Message from "./components/Message";
import ChannelSelector from "./components/ChannelSelector";
import TemplatesList from "./components/Templates/TemplatesList";
import PlaceHolderList from "./components/placheholders/PlaceHolderList";
import Attachments from "./components/attachment/Attachments";
import i18next from "i18next";
import ReactFlagsSelect from "react-flags-select";

import SearchTextInput from "./components/search/SearchTextInput";
import SearchPhoneInput from "./components/search/SearchPhoneInput";
import { getUrlParams } from "./utilities/urlParams";
import { getCountryFromURL } from "./utilities/language";
import { shouldHideModal, isSMSAbleToBeSended, isSmsChannel, generateErrorMessage, isEmpty } from "./utilities/validations";
import { getGroupWithUser, createTicket } from "./services/zendesk";

import NewUserForm from "./components/NewUserForm";

export default function App() {
  const { t } = useTranslation();
  const [showPage, setShowPage] = useState({ show: false, error: false, message: t("loading") });
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [agentCountryCode, setAgentCountryCode] = useState("");
  const [modalMessageClass, setModalMessageClass] = useState("hidden");
  const [modalMessageTitle, setModalMessageTitle] = useState("");
  const [modalMessageDescription, setModalMessageDescription] = useState("");
  const [showTemplatesList, setshowTemplatesList] = useState(false);
  const [token, setToken] = useState("");
  const [apiKey, setapiKey] = useState("");
  const [templates, setTemplates] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [templatesSms, setTemplatesSms] = useState([]);
  const [templatesWhatsapp, setTemplatesWhatsapp] = useState([]);
  const [messageValue, setMessageValue] = useState("");
  const [sendingIsAble, setSendingIsAble] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState({ name: "", phone: "", id: "", previousName: "", previousPhone: "", previousId: "", optin: false, previousOptin: false });
  const [modalType, setModalType] = useState("error");
  const [showUserCreationForm, setShowUserCreationForm] = useState(false);
  const [formatPhone, setFormatPhone] = useState("");
  const [autoCenter, setAutoCenter] = useState("");
  const [autoCenterLandlinePhoneNumber, setAutoCenterLandlinePhoneNumber] = useState("");
  const [agentId, setAgentId] = useState("");
  const [agentName, setAgentName] = useState("");
  const [showTimeLoader, setShowTimeLoader] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showBlock, setShowBlock] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [showReload, setShowReload] = useState(false);
  const [tokenBlockScreenShow, setTokenBlockScreenShow] = useState(false);
  const [activeOptin, setActiveOptin] = useState(false);
  const [channelValue, setChannelValue] = useState(config.CHANNEL_SMS);
  const [placeholders, setPlaceHolders] = useState([]);
  const [placeholdersText, setPlaceHoldersText] = useState([]);
  const [updatingAttachment, setUpdatingAttachment] = useState(false);
  const [smsChecked, setSmsChecked] = useState(true);
  const [whatsappChecked, setWhatsappChecked] = useState(false);
  const [acceptedFormatsAttachments, setAcceptedFormatsAttachments] = useState(config.SMS_ATTACHMENTS);
  const [showChannelSelector, setShowChannelSelector] = useState(false);
  const [integrationPhone, setIintegrationPhone] = useState("");


  // Handle  message being sent by the user
  const [currentMessage, setCurrentMessage] = useState("");
  let decodedToken = null;

  /**
   * Show token block screen if the token is older than 55 minutes
   * 
   * @param {*} date2 
   */
  const timeDifference = (date2) => {
    if (decodedToken) {
      let expirationDate = (decodedToken.exp - 300) * 1000;
      if (date2 >= expirationDate && !tokenBlockScreenShow) {
        setTokenBlockScreenShow(true);
      }
    }
  };

  /**
   * Function that returns the message to be send
   */
  const getMessageToBeSend = () => {
    return {
      "text": messageValue,
      "phone_number": user.phone,
      "autocenter": autoCenter,
      "agentId": agentId,
      "userId": user.id.toString(),
      "userName": user.name,
      "attachments": selectedFiles,
      "language": agentCountryCode.toLowerCase(),
      "finalTemplateName": currentTemplate.finalName ?? "",
      "useCaseTag": isEmpty(currentTemplate.use_case_tag) ? "no_use_case_tag" : currentTemplate.use_case_tag,
    };
  };

  /**
   * Handle sending of a sms or whatsapp and creating ticket
   */
  const onSendMessage = async () => {
    setShowTimeLoader(true);
    setShowBlock(true);
    const message = getMessageToBeSend();
    if (!isSmsChannel(channelValue)) {
      message.placeholders = placeholdersText;
      message.templateName = currentTemplate.templateName;
    }
    const id = await createTicket(token, apiKey, message);
    setTicketId(id);
    if (id) {
      setCurrentMessage("TICKET_OK");
    } else {
      setCurrentMessage("TICKET_ERROR");
    }
  };

  // This only executes one time, on loading
  useEffect(async () => {
    setInterval(() => {
      timeDifference(Date.now());
    }, 1000);
    const urlParams = getUrlParams();
    setInterval(() => {
      timeDifference(Date.now());
    }, 1000);
    if (!showPage.show && urlParams !== null) {
      setSessionUrlParams(urlParams);
      const authenticated = await jwtAuthentication(urlParams.get("t"), urlParams.get("x"));
      if (!authenticated.error) {
        const tokenUrl = urlParams.get("t");
        const xapikeyUrl = urlParams.get("x");
        decodedToken = jwt_decode(tokenUrl);
        setToken(tokenUrl);
        setapiKey(xapikeyUrl);
        setShowPage({ ...showPage, error: false, show: true });
        const country = getCountryFromURL(urlParams.get("c"));
        i18next.changeLanguage(country.toLowerCase());
        setAgentId(urlParams.get("a"));
        setSelectedCountryCode(country.toUpperCase());
        setAgentCountryCode(country.toUpperCase());
        const groupWithUser = await getGroupWithUser(tokenUrl, xapikeyUrl, urlParams.get("gi"), urlParams.get("a"));
        setAgentName(groupWithUser.userName);
        setAutoCenter(groupWithUser.group.name);
        setIintegrationPhone(groupWithUser.integrationPhone);
        setAutoCenterLandlinePhoneNumber(groupWithUser.landlinePhoneNumber);
      } else {
        setShowPage({ ...showPage, error: true, message: t("not_authorized") });
      }
    }
    if (urlParams === null) {
      setShowPage({ ...showPage, error: true, message: t("not_authorized") });
    }
  }, []);

  useEffect(() => {
    setUser({ name: "", phone: "", id: "", previousName: "", previousPhone: "", previousId: "", optin: false, previousOptin: false })
  }, [selectedCountryCode]);

  /**
   * Save to sessionStorage the url parameters
   * @param {*} urlParams 
   */
  function setSessionUrlParams(urlParams) {
    sessionStorage.setItem("st", urlParams.get("st"));
    sessionStorage.setItem("an", urlParams.get("an"));
    sessionStorage.setItem("ae", urlParams.get("ae"));
  }

  /**
   * Modals are closed when click out
   * @param {*} element DOM element clicked
   */
  const ManageCloseModal = (element) => {
    if (shouldHideModal(modalMessageClass, element)) {
      setModalMessageClass("hidden");
    }
  };

  /**
   * When required to send sms inputs changes, updates
   * if sending is able and the message error
   */
  useEffect(() => {
    const smsToSendParams = {
      messageValue,
      user,
      selectedCountryCode,
      formatPhone,
      placeholdersText,
      channelValue,
      selectedFiles,
      currentTemplate
    };
    if (isSMSAbleToBeSended(smsToSendParams)) {
      setErrorMessage("");
      setSendingIsAble(true);
    } else {
      setErrorMessage("");
      setSendingIsAble(false);
      setErrorMessage(generateErrorMessage(smsToSendParams, t));
    }
    if (user.id !== "") {
      setActiveOptin(user.optin);
    }

  }, [user, messageValue, selectedCountryCode, formatPhone, channelValue, placeholdersText, selectedFiles, currentTemplate]);

  /**
   * Manage channel depending on user optin
   */
  useEffect(() => {
    if (!isSmsChannel(channelValue) && !activeOptin) {
      setChannelValue(config.CHANNEL_SMS);
    } else if (isSmsChannel(channelValue) && activeOptin && showChannelSelector) {
      setChannelValue(config.CHANNEL_WHATSAPP);
    }
  }, [activeOptin]);

  /**
   * It only shows the channel selector for some certain autocenters depending on the country
   */
  useEffect(() => {
    let autocenterCountry = autoCenter.split("_").slice(-2)[0];
    setShowChannelSelector((config.WHATSAPP_AUTOCENTERS.findIndex(whatsappAutocenter => whatsappAutocenter.toUpperCase() === autocenterCountry.toUpperCase()) !== -1));

  }, [autoCenter]);

  /**
   * Checks sended message status and show differents messages
   */
  useEffect(() => {
    if (currentMessage !== "" && showTimeLoader) {
      setShowTimeLoader(false);
      setModalMessageClass("");
      let type = "";
      let description = "";
      let title = "";
      if (currentMessage === "TICKET_OK") {
        type = "success";
        title = `${t("message_successfull_ticket")} ${ticketId}`;
        setShowReload(true);
      } else {
        type = "error";
        title = t("error_sending");
        description = t("error_creating_ticket");
      }
      setModalMessageTitle(title);
      setModalType(type);
      setModalMessageDescription(description);
    }
  }, [currentMessage]);


  // When channel value changes message is emptied and
  // only accepts images as format
  useEffect(() => {
    setMessageValue("");
    setCurrentTemplate({});
    if (isSmsChannel(channelValue)) {
      setPlaceHolders(null);
      setAcceptedFormatsAttachments(config.SMS_ATTACHMENTS);
    } else {
      setAcceptedFormatsAttachments(config.WHATSAPP_ATTACHMENTS);
    }
    setWhatsappChecked(!isSmsChannel(channelValue));
    setSmsChecked(isSmsChannel(channelValue));
  }, [channelValue]);

  // Fills arrays for sms and whatsapp templates
  useEffect(() => {
    let smsTemplates = [];
    let whatsappTemplates = [];
    for (let template of templates) {
      if (template.whatsapp) {
        whatsappTemplates.push(template);
      } else {
        smsTemplates.push(template);
      }
    }
    setTemplatesSms(smsTemplates);
    setTemplatesWhatsapp(whatsappTemplates);
  }, [templates]);

  /**
   * Send message or show errors
   */
  const manageSending = async () => {
    if (sendingIsAble) {
      await onSendMessage();
    } else {
      setModalMessageClass("");
      setModalMessageTitle(t("error_sending"));
      setModalMessageDescription(errorMessage);
      setModalType("error");
    }
  };


  /**
   * Render  
   */
  if (showPage.show) {
    return (
      <ThemeProvider >
        {tokenBlockScreenShow && <div className="tokenMessage"><span>{t("token_expired_message")}</span></div>}
        <TemplatesList translation={t}
          showingValue={showTemplatesList ? "showingTemplatesList" : "hidden"}
          setshowTemplatesList={setshowTemplatesList}
          token={token}
          apiKey={apiKey}
          templates={isSmsChannel(channelValue) ? templatesSms : templatesWhatsapp}
          setTemplates={setTemplates}
          setmessageValue={setMessageValue}
          user={user}
          agentCountryCode={agentCountryCode}
          agentName={agentName}
          integrationPhone={integrationPhone}
          setPlaceHolders={setPlaceHolders}
          setCurrentTemplate={setCurrentTemplate}
          autoCenter={autoCenter}
          autoCenterLandlinePhoneNumber={autoCenterLandlinePhoneNumber} />
        <Message modalClass={modalMessageClass + " messageModal"} setModalClass={setModalMessageClass} title={modalMessageTitle}
          type={modalType} description={modalMessageDescription} setShowBlock={setShowBlock}
          translations={t} showReload={showReload} />
        <div className={showBlock ? " blockedPage " : " blockedPage hidden"} onClick={(e) => e.stopPropagation()}></div>
        <div className={!showTemplatesList ? "container" : "container hidden"} onClick={(e) => ManageCloseModal(e.target)}>
          {showUserCreationForm && <NewUserForm setShowUserCreationForm={setShowUserCreationForm} setUser={setUser} token={token}
            apiKey={apiKey} selectedCountryCode={selectedCountryCode} setSelectedCountryCode={setSelectedCountryCode} />}
          <div className="mb-3 customerContainer">
            <a href="#" className="createCustomer" onClick={() => setShowUserCreationForm(true)}>+</a>
            <SearchTextInput
              token={token}
              apiKey={apiKey}
              translation={t}
              userValue={user}
              setuservalue={setUser}
              type="name"
              selectedCountry={selectedCountryCode}
            />
          </div>
          <div className="mb-3 country">
            <ReactFlagsSelect
              selected={selectedCountryCode}
              countries={config.COUNTRIES}
              customLabels={config.COUNTRIES_LABELS}
              placeholder="00XX"
              optionsSize={14}
              className="menu-flags"
              fullWidth={false}
              onSelect={countryCode => setSelectedCountryCode(countryCode)}
              disabled
            />
            <SearchPhoneInput
              token={token}
              apiKey={apiKey}
              translation={t}
              userValue={user}
              setuservalue={setUser}
              type="phone"
              selectedCountryCode={selectedCountryCode}
              setFormatPhone={setFormatPhone}
            />
          </div>
          <div>
            <input type="text" className="form-control" id="autocenter" placeholder={t("autocenter")} />
          </div>
          {showChannelSelector ?
            <ChannelSelector translations={t} activeOptin={activeOptin} setChannelValue={setChannelValue}
              setModalMessageClass={setModalMessageClass} setModalMessageTitle={setModalMessageTitle}
              setModalType={setModalType} setModalMessageDescription={setModalMessageDescription}
              setPlaceHolders={setPlaceHolders} updatingAttachment={updatingAttachment}
              smsChecked={smsChecked} whatsappChecked={whatsappChecked} /> : ""
          }
          <div className="mb-3 messageText">
            <textarea className="form-control" id="message" rows="8"
              placeholder={!isSmsChannel(channelValue) ? t("select_template") : t("type_message")}
              value={messageValue} disabled={!isSmsChannel(channelValue)}
              onChange={(e) => setMessageValue(e.target.value)}></textarea>
          </div>
          <div id="divSendingLoader">
            {showTimeLoader ? <Inline size={120} color={PALETTE.blue[600]} className={"loader"} /> : ""}
          </div>
          <a href="#" className="right" onClick={() => setshowTemplatesList(true)}>{t("apply_template")}</a>
          <Attachments setModalMessageClass={setModalMessageClass} translations={t} setModalMessageTitle={setModalMessageTitle}
            setModalType={setModalType} setModalMessageDescription={setModalMessageDescription} setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles} token={token} apiKey={apiKey} currentTemplate={currentTemplate}
            channelValue={channelValue} setCurrentTemplate={setCurrentTemplate}
            updatingAttachment={updatingAttachment} setUpdatingAttachment={setUpdatingAttachment} acceptedFormatsAttachments={acceptedFormatsAttachments} />
          {placeholders && placeholders.length > 0 ?
            <PlaceHolderList placeholders={placeholders} setMessageValue={setMessageValue}
              currentTemplate={currentTemplate} setPlaceHoldersText={setPlaceHoldersText}
              messageValue={messageValue} user={user} /> : ""
          }
          <div className="buttonContainer">
            <button id="sendMessage"
              className={sendingIsAble ? "" : "buttonDisabled"}
              onClick={manageSending}
            >{t("send")}</button>
          </div>

        </div>
      </ThemeProvider >
    );
  }

  return renderErrorHTML(showPage);
}

/**
 * Show loading/error page to the app
 * @param {*} showPage
 */
function renderErrorHTML(showPage) {
  return (<ThemeProvider>
    <div className="loadingMessageApp">
      {!showPage.error
        ? <Inline size={32} color={PALETTE.blue[600]} />
        : <Message type="warning" title="Error" description={showPage.message} modalClass=""
          setModalClass={() => { }} />
      }
    </div>
  </ThemeProvider>);
}
