import React, { useState, useEffect } from "react";
import { searchUsers } from "../../services/zendesk";
import PropTypes from "prop-types";
import SearchUserList from "./SearchUserList";
import { config } from "../../config";
import { checkNumberFormat, deletePrefix } from "../../utilities/phoneNumbers";
import { Inline } from "@zendeskgarden/react-loaders";
import { PALETTE } from "@zendeskgarden/react-theming";

const SearchPhoneInput = ({ token,
  apiKey,
  translation,
  userValue,
  setuservalue,
  selectedCountryCode,
  setFormatPhone }) => {
  let [keyword, setKeyword] = useState(userValue.phone ? userValue.phone : "");
  let [users, setUsers] = useState([]);
  let [displayList, setDisplayList] = useState(false);
  let [timer, setTimer] = useState(null);
  let [loaderSearch, setLoaderSearch] = useState(false);
  let [errors, setErrors] = useState("");

  /**
   * Updated the user list box
   * @param {BaseSyntheticEvent} event 
   */
  const searchUsersWithKeyword = async (event) => {
    setKeyword(event.target.value);
    const { value } = event.target;

    // when the input value changes we delete the timer to start a new one
    if (timer) {
      clearTimeout(timer);
    }
    const valuePhone = deletePrefix(selectedCountryCode, value.replace("+", "").replace(/ /g, ""));
    if (!isNaN(keyword) && checkNumberFormat(selectedCountryCode, valuePhone, "search")) {
      setErrors("");
      // We are creating a timer to not start searching with each of the inputs
      // we are waiting 1 second to start the search adn at least 2 characters
      setTimer(setTimeout(async function () {
        if (valuePhone.length > 2 && userValue.phone !== valuePhone) {
          let valueToSearch = `${config.COUNTRIES_LABELS[selectedCountryCode]}${valuePhone}`;
          setLoaderSearch(true);

          let usersResults = await searchUsers(token, apiKey, valueToSearch);
          setLoaderSearch(false);
          let usersCorrectFormat = filterByPhone(usersResults.data.users, selectedCountryCode, valuePhone);
          if (usersCorrectFormat.length > 0) {
            setUsers(usersCorrectFormat);
            setDisplayList(true);
          } else {
            setUsers([]);
            setDisplayList(false);
          }
        } else {
          setUsers([]);
          setDisplayList(false);
        }
      }, 800));
    } else {
      if (value !== "" && valuePhone.length !== 0) {
        setErrors(translation("phone_invalid"));
        setLoaderSearch(false);
        setUsers([]);
        setDisplayList(false);
      } else {
        setErrors("");
      }
    }
  };

  // Get the field empty updating the state of it
  const cleanField = () => {
    if (userValue) {
      setErrors("");
      setuservalue({ ...userValue, name: "", phone: "", id: "", optin: false });
    }
  };

  /**
   * This function get the previous values stored on the state
   */
  const restoreField = () => {
    setTimer(setTimeout(() => {
      if (userValue.name === "") {
        setKeyword(userValue.previousPhone);
        setuservalue({
          ...userValue,
          name: userValue.previousName,
          phone: userValue.previousPhone,
          id: userValue.previousId,
          optin: userValue.previousOptin
        });
      }
    }, 200));
  };

  // Is only executed when the user is selected
  useEffect(() => {
    if (userValue.name !== "") {
      clearTimeout(timer);
    }
    let phoneValue = updatePhoneField(userValue, setKeyword, setDisplayList, setFormatPhone);
    if (userValue.phone !== "") {
      let hasCountryCode = config.COUNTRIES.filter(country => userValue.phone.startsWith(config.COUNTRIES_LABELS[country]));
      if (hasCountryCode.length > 0) {
        let isValidPhone = checkNumberFormat(hasCountryCode[0], phoneValue);
        if (phoneValue !== "" && !isValidPhone) {
          setErrors(translation("phone_invalid"));
        } else {
          setErrors("");
        }
      } else {
        setErrors(translation("phone_invalid_country_code"));
      }
    } else {
      setErrors("");
    }
  }, [userValue]);

  // Hide the list when the country is changed
  useEffect(() => {
    if (selectedCountryCode) {
      setDisplayList(false);
    }
  }, [selectedCountryCode]);

  let input = getInputConfig();

  return (
    <>
      <input
        type={input.type}
        className={`${input.classes} ${errors ? "errorField" : ""}`}
        id={input.id}
        placeholder={translation(input.key)}
        key={input.key}
        value={keyword}
        onChange={(event) => searchUsersWithKeyword(event)}
        onClick={cleanField}
        onBlur={restoreField}
        autoComplete="off"
      />
      {errors && <em className="errorText" style={{ textAlign: "right" }}>{errors}</em>}
      {loaderSearch ? <Inline size={32} color={PALETTE.blue[600]} className={`${input.type}loader`} /> : ""}
      <SearchUserList
        users={users}
        show={displayList ? "show" : "hide"}
        setuservalue={setuservalue}
        keyword={keyword}
      />
    </>
  );
};

SearchPhoneInput.propTypes = {
  token: PropTypes.string,
  apiKey: PropTypes.string,
  translation: PropTypes.func,
  userValue: PropTypes.object,
  setuservalue: PropTypes.func,
  type: PropTypes.string,
  selectedCountryCode: PropTypes.string,
  setFormatPhone: PropTypes.func,
};

/**
 * Generates and object with the configuration of the input
 */
function getInputConfig() {
  return {
    "key": "phone",
    "type": "tel",
    "id": "phone-number",
    "classes": "form-control phoneSelect"
  };
}

/**
 * Updates the phone number
 * @param {string} userValue 
 * @param {Function} setKeyword 
 * @param {Function} setDisplayList 
 * @param {Function} setFormatPhone
 */
function updatePhoneField(userValue, setKeyword, setDisplayList, setFormatPhone) {
  let phoneValue = "";
  if (userValue.phone !== "" && userValue.phone !== undefined) {
    phoneValue = parsePhoneNumber(userValue);
  } else {
    phoneValue = userValue.phone;
  }
  setFormatPhone(phoneValue);
  setKeyword(phoneValue);
  setTimeout(() => {
    setDisplayList(false);
  }, 1000);

  return phoneValue;
}

/**
 * Function to get the country code for the phone number and update both fields
 * @param {String} userValue 
 */
function parsePhoneNumber(userValue) {

  let countryCode = "";
  userValue.phone = userValue.phone.replace("+", "");

  for (let country of Object.keys(config.COUNTRIES_LABELS)) {
    const startWithCountryCodeWithZeros = userValue.phone.startsWith(config.COUNTRIES_LABELS[country]);
    const startWithCountryCodeWithoutZeros = userValue.phone.startsWith(parseInt(config.COUNTRIES_LABELS[country]));
    if (startWithCountryCodeWithZeros || startWithCountryCodeWithoutZeros) {
      countryCode = country;
    }
  }
  let phoneValue = userValue.phone.replace(config.COUNTRIES_LABELS[countryCode], "");
  return phoneValue;

}
/**
 * Returns user with correct phone format
 * @param {array of objects} users 
 * @param {string} selectedCountry 
 * @param {string} keyword
 * @returns 
 */

function filterByPhone(users, selectedCountry, keyword) {
  let prefix = config.COUNTRIES_LABELS[selectedCountry];
  let usersCorrectFormat = [];
  for (let user of users) {
    if (user.role === "end-user") {
      for (let identity of user.identities) {
        let phoneWithoutSpaces = identity.value.replace(" ", "");
        let phoneWithoutPrefix = phoneWithoutSpaces.replace(prefix, "");
        if (phoneWithoutSpaces.startsWith(prefix) &&
          phoneWithoutPrefix.includes(keyword) &&
          checkNumberFormat(selectedCountry, phoneWithoutPrefix)) {
          user.identities = [identity];
          usersCorrectFormat.push(user);
          break;
        }
      }
    }
  }
  return usersCorrectFormat;
}




export default SearchPhoneInput;