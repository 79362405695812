const config = {
    WS_URL: "wss://28lcdv8n6j.execute-api.eu-west-1.amazonaws.com/dev?Auth=",
    COUNTRIES: ["FR", "ES", "PT", "DE", "IT", "BE", "AT"],
    COUNTRIES_LABELS: { "FR": "0033", "ES": "0034", "PT": "00351", "DE": "0049", "IT": "0039", "BE": "0032", "AT": "0043" },
    DEFAULT_LANGUAGE: "fr",
    DEFAULT_COUNTRY_CODE: "FR",
    MAX_FILE_SIZE: 49,
    DEFAULT_GROUP: "ENGLOS_Manager_AC_FR_0001",
    CHANNEL_SMS: "sms",
    CHANNEL_WHATSAPP: "whatsapp",
    WHATSAPP_AUTOCENTERS: ["ES", "PT"],
    SMS_ATTACHMENTS: ".jpeg,.png,.jpg,.gif,.pdf,.doc,.docx,.mp4,.3gpp",
    WHATSAPP_ATTACHMENTS: ".jpeg,.png,.jpg,.gif",
    REQUESTER_NAME_PLACEHOLDER: ["Nombre del demandante", "Nom du demandeur", "Nome do requerente"]
};

export { config };