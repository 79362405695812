/**
 * Recover object with URL params
 */
function getUrlParams() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("t") && urlParams.has("x") && urlParams.has("a") && urlParams.has("c")) {
      return urlParams;
    }
    return null;
}

export {getUrlParams};