import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { uploadFileToApi, presignedURL, uploadFileToBucket } from "../../services/uploadFiles";
import { config } from "../../config";

import AttachmentList from "./AttachmentList";

import { ImAttachment } from "react-icons/im";
import { Inline } from "@zendeskgarden/react-loaders";
import { PALETTE } from "@zendeskgarden/react-theming";

/**
 * Manage attachments and attachments errors
 * @param {func} setModalMessageClass
 * @param {func} translations
 * @param {func} setModalMessageTitle 
 * @param {func} setModalType
 * @param {func} setModalMessageDescription
 * @param {func} setSelectedFiles,
 * @param {func} selectedFiles
 * @param {string} token
 * @param {string} apiKey
 * @param {string} currentTemplate
 * @param {string} channelValue
 * @param {func} selectedFiles setCurrentTemplate
 * @param {func} setUpdatingAttachment
 * @param {bool} updatingAttachment
 * @param {string} acceptedFormatsAttachments
 * @returns 
 */
const Attachments = ({
    setModalMessageClass,
    translations,
    setModalMessageTitle,
    setModalType,
    setModalMessageDescription,
    setSelectedFiles,
    selectedFiles,
    token,
    apiKey,
    currentTemplate,
    channelValue,
    setCurrentTemplate,
    setUpdatingAttachment,
    updatingAttachment,
    acceptedFormatsAttachments
}) => {

    const SMS_LIMIT_ATTACHMENTS = 5;
    const WHATSAPP_LIMIT_ATTACHMENTS = 1;
    const [limitFiles, setLimitFiles] = useState(false);
    const [numberLimitOfFiles, setNumberLimitOfFiles] = useState(SMS_LIMIT_ATTACHMENTS);

    /**
     * 
     * @param {*} presignedUrl 
     * @param {*} file 
     * @param {*} filename 
     * @returns 
     */
    const getFormData = (presignedUrl, file, filename) => {
        const formData = new FormData();
        Object.keys(presignedUrl.url.fields).forEach(key => {
            formData.append(key, presignedUrl.url.fields[key]);
        });
        // Current file has to be appended last.
        formData.append("file", file, filename);
        return formData;
    };

    /**
     * Upload a field to api and show it in a list
     * @param {object} file 
     */
    const uploadFile = async (file) => {
        if (isCorrectExtension(file, acceptedFormatsAttachments)) {
            setUpdatingAttachment(true);
            let response = false;
            const fileDate = Date.now();
            // generate a presigned URL
            const fileName = `${fileDate}-${file.name}`;
            const presignedUrl = await presignedURL(fileName, token, apiKey);
            const formData = getFormData(presignedUrl, file, fileName);
            const responseFile = await uploadFileToBucket(presignedUrl.url.url, formData);
            if (responseFile) {
                response = await uploadFileToApi(fileName, token, apiKey);
            }
            setUpdatingAttachment(false);
            if (response) {
                const newFile = {
                    "index": selectedFiles.length,
                    "name": file.name,
                    "token": response.token,
                    "url": response.file_url,
                    "uniquename": fileName
                };
                setSelectedFiles([...selectedFiles, newFile]);
            } else {
                setModalMessageTitle(translations("error_uploading_file"));
                setModalType("error");
                setModalMessageDescription("");
                setModalMessageClass("");
            }
        } else {
            setModalMessageTitle(translations("error_invalid_extension"));
            setModalType("error");
            setModalMessageDescription("");
            setModalMessageClass("");
        }

    };

    /**
     *  Manage uploading files
     * @param {object} file 
     */
    const manageFiles = async (file) => {
        // If file size is too size is no updaloaded and warn user
        if (file.length > 0 && (file[0].size / 1024 / 1024) > config.MAX_FILE_SIZE) {
            setModalMessageTitle(translations("error_limit_size"));
            setModalType("error");
            setModalMessageDescription("");
            setModalMessageClass("");
        } else if (selectedFiles.length < numberLimitOfFiles && file.length > 0) {
            await uploadFile(file[0]);
        }
    };

    /**
     * Checks if there are more than number limit of files attached
     */
    useEffect(() => {
        setLimitFiles(selectedFiles.length >= numberLimitOfFiles ? true : false);
    }, [selectedFiles, numberLimitOfFiles]);


    /**
     * Checks limit of files
     */
    useEffect(() => {
        setNumberLimitOfFiles(channelValue === config.CHANNEL_SMS ? SMS_LIMIT_ATTACHMENTS : WHATSAPP_LIMIT_ATTACHMENTS);
        setCurrentTemplate({});
        setSelectedFiles([]);
    }, [channelValue]);

    /**
     * Empties attachments if channel is whatsapp and template change 
     */
    useEffect(() => {
        if (channelValue === config.CHANNEL_WHATSAPP) {
            setSelectedFiles([]);
        }
    }, [currentTemplate]);


    /**
     * Empty files from file input in order to avoid
     * strange behaviour
     */
    const manageAttachment = () => {
        document.getElementById("formFile").value = "";
        if (selectedFiles.length > 4) {
            setModalMessageTitle(translations("error_limit_files"));
            setModalType("error");
            setModalMessageDescription("");
            setModalMessageClass("sticky");
        }
    };

    const isInputDisabled = inputDisabled(limitFiles, updatingAttachment, channelValue, currentTemplate);
    return (

        <>
            <div className="mb-3 attachfile">
                <label htmlFor="formFile" className="form-label">
                    <div id="attachmentDiv" onClick={manageAttachment}>
                        <ImAttachment size={18} color={isInputDisabled ? "red" : "black"} />
                    </div>
                    <input type="file" id="formFile" name="formFile" style={{ display: "none" }}
                        accept={acceptedFormatsAttachments}
                        onChange={e => { manageFiles(e.target.files); }}
                        disabled={isInputDisabled ? true : false} />
                </label>
            </div>
            <AttachmentList attachments={selectedFiles} setAttachments={setSelectedFiles} setLimitFiles={setLimitFiles} />
            {updatingAttachment ? <Inline size={32} color={PALETTE.blue[600]} className={"loader"} /> : ""}

        </>
    );
};

/**
 * Cheks if input must be disabled
 * @param {*} limitFiles 
 * @param {*} updatingAttachment 
 * @param {*} channelValue 
 * @param {*} currentTemplate 
 * @returns 
 */
function inputDisabled(limitFiles, updatingAttachment, channelValue, currentTemplate) {
    let notRequiredAttachment = (channelValue === config.CHANNEL_WHATSAPP && (currentTemplate.media === "none" || !currentTemplate.media));
    return limitFiles || updatingAttachment || notRequiredAttachment;
}

/**
 * Return true if correct extension
 * @param {*} file 
 * @param {*} extensions 
 * @returns 
 */
function isCorrectExtension(file, extensions) {
    let extension = "";
    try {
        extension = file.name.substring(file.name.length - 4);
    } catch (error) {
        return false;
    }
    return extensions.includes(extension);
}

Attachments.propTypes = {
    setModalMessageClass: PropTypes.func,
    translations: PropTypes.func,
    setModalMessageTitle: PropTypes.func,
    setModalType: PropTypes.func,
    setModalMessageDescription: PropTypes.func,
    setSelectedFiles: PropTypes.func,
    selectedFiles: PropTypes.array,
    token: PropTypes.string,
    apiKey: PropTypes.string,
    currentTemplate: PropTypes.object,
    channelValue: PropTypes.string,
    setCurrentTemplate: PropTypes.func,
    setUpdatingAttachment: PropTypes.func,
    updatingAttachment: PropTypes.bool,
    acceptedFormatsAttachments: PropTypes.string,
};


export default Attachments;