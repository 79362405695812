import React, { useState, useEffect } from "react";
import { searchUsers } from "../../services/zendesk";
import PropTypes from "prop-types";
import SearchUserList from "./SearchUserList";
import { Inline } from "@zendeskgarden/react-loaders";
import { PALETTE } from "@zendeskgarden/react-theming";
import { checkNumberFormat } from "../../utilities/phoneNumbers";
import { config } from "../../config";

/**
 * Custom search input text for contacts on Zendesk
 * @param {string} token 
 * @param {string} apiKey 
 * @param {func} translation 
 * @param {object} userValue 
 * @param {func} setuservalue  
 * @param {string} selectedCountry
 * @returns 
 */
const SearchTextInput = ({ token, apiKey, translation, userValue, setuservalue, selectedCountry }) => {
  let [keyword, setKeyword] = useState(userValue.name);
  let [users, setUsers] = useState([]);
  let [displayList, setDisplayList] = useState(false);
  let [timer, setTimer] = useState(null);
  let [loaderSearch, setLoaderSearch] = useState(false);

  /**
   * Updated the user list box
   * @param {BaseSyntheticEvent} event 
   */
  const searchUsersWithKeyword = async (event) => {

    setKeyword(event.target.value);
    const { value } = event.target;
    // when the input value changes we delete the timer to start a new one
    if (timer) {
      clearTimeout(timer);
    }

    // We are creating a timer to not start searching with each of the inputs
    // we are waiting 1 second to start the search adn at least 2 characters
    setTimer(getUsers({ value, userValue, keyword, setLoaderSearch, token, apiKey, setUsers, setDisplayList }, selectedCountry));
  };

  // Get the field empty updating the state of it
  const cleanField = () => {
    if (userValue) {
      setuservalue({ ...userValue, name: "", phone: "", id: "", optin: false });
    }
  };

  // Is only executed when the user is selected
  useEffect(() => {
    setKeyword(userValue.name);
    setTimeout(() => {
      setDisplayList(false);
    }, 100);
  }, [userValue]);

  const restoreField = () => {
    if (userValue.name === "") {
      setKeyword(userValue.previousName);
      setuservalue({
        ...userValue,
        name: userValue.previousName,
        phone: userValue.previousPhone,
        id: userValue.previousId,
        optin: userValue.previousOptin
      });
    }
  };

  let input = getInputConfig();

  return (

    <>
      <input
        type={input.type}
        className={input.classes}
        id={input.id}
        placeholder={translation(input.key)}
        key={input.key}
        value={keyword}
        onChange={(event) => searchUsersWithKeyword(event)}
        onClick={cleanField}
        onBlur={restoreField}
        autoComplete="off"
      />
      {loaderSearch ? <Inline size={32} color={PALETTE.blue[600]} className={`${input.type}loader`} /> : ""}
      <SearchUserList
        users={users}
        show={displayList ? "show" : "hide"}
        setuservalue={setuservalue}
        keyword={keyword}
      />
    </>
  );
};

SearchTextInput.propTypes = {
  token: PropTypes.string,
  apiKey: PropTypes.string,
  translation: PropTypes.func,
  userValue: PropTypes.object,
  setuservalue: PropTypes.func,
  selectedCountry: PropTypes.string,
};

/**
 * Generates and object with the configuration of the input
 */
function getInputConfig() {
  return {
    "key": "requester",
    "type": "text",
    "id": "requester",
    "classes": "form-control customerSelect"
  };
}

/**
 * Function returning a setTimeout function to search for users
 * @param {*} param0 
 * @param {string} selectedCountry 
 */
function getUsers({ value, userValue, keyword, setLoaderSearch, token, apiKey, setUsers, setDisplayList }, selectedCountry) {
  return setTimeout(async function () {
    if (value.length > 2 && userValue.name !== value) {
      let valueToSearch = `${value} ${config.COUNTRIES_LABELS[selectedCountry]}`;
      if (isNaN(keyword)) {
        setLoaderSearch(true);
        let usersResults = await searchUsers(token, apiKey, valueToSearch);
        let usersCorrectFormat = filterByPhone(usersResults.data.users, selectedCountry);
        setLoaderSearch(false);
        if (usersCorrectFormat.length > 0) {
          setUsers(usersCorrectFormat);
          setDisplayList(true);
        } else {
          setUsers([]);
          setDisplayList(false);
        }
      }
    } else {
      setUsers([]);
      setDisplayList(false);
    }
  }, 800);
}

/**
 * Returns user with correct phone format
 * @param {array of objects} users 
 * @param {string} selectedCountry 
 * @returns 
 */

function filterByPhone(users, selectedCountry) {
  let prefix = config.COUNTRIES_LABELS[selectedCountry];
  let usersCorrectFormat = [];
  for (let user of users) {
    if (user.role === "end-user") {
      for (let identity of user.identities) {
        if (identity.value.replace(" ", "").startsWith(prefix) &&
          checkNumberFormat(selectedCountry, identity.value.replace(prefix, "").replace(" ", ""))) {
          user.identities = [identity];
          usersCorrectFormat.push(user);
          break;
        }
      }
    }
  }
  return usersCorrectFormat;
}

export default SearchTextInput;