import axios from "axios";

/**
 * Validation for url parameters
 * @param {*} token 
 * @param {*} xApiKey 
 */
async function jwtAuthentication(token, xApiKey) {
    const domain = `https://${sessionStorage.getItem("an")}.execute-api.eu-west-1.amazonaws.com`;
    const endpoint = `/${sessionStorage.getItem("st")}/webview/validatetoken`;
    const config = {
        method: "post",
        url: `${domain}${endpoint}`,
        headers: {
            "Authorization": `Basic ${token}`,
            "x-api-key": xApiKey
        }
    };

    try {
        let response = await axios(config);
        return { error: false, message: JSON.stringify(response.data) };
    } catch (error) {
        console.error(error);
        return { error: true, message: "You are not allowed to view this page." };
    }
}

export { jwtAuthentication };