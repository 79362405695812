import React from "react";
import { Alert, Title, Close } from "@zendeskgarden/react-notifications";
import PropTypes from "prop-types";

/**
 * 
 * @param {string} type 
 * @param {string} title 
 * @param {string} description 
 * @param {funct} setModalClass
 * @param {string} modalClass
 * @param {funct} setAppBlocked
 * @param {funct} setShowBlock
 * @returns 
 */
const Message = ({
  type,
  title,
  description,
  modalClass,
  setModalClass,
  translations,
  setShowBlock,
  showReload
}) => {


  /**
   * Hide the modal
   */
  const hiddenModal = () => {
    setModalClass("hidden");
    // If there is an error sending message
    if (translations("error_sending") === title) {
      setShowBlock(false);
    }
  };

  return (
    <div className={modalClass} id="modalMessage">
      <Alert type={type}>
        <Title>{title}</Title>
        <div className="descriptionMessage" dangerouslySetInnerHTML={{ __html: description }}></div>
        {showReload
          ? <button className="reloadBtn" onClick={() => location.reload()}>{translations("send_new_message")}</button>
          : <Close aria-label="Close Alert" onClick={hiddenModal} />
        }
      </Alert>
    </div>
  );
};



Message.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  modalClass: PropTypes.string,
  setModalClass: PropTypes.func,
  translations: PropTypes.func,
  setAppBlocked: PropTypes.func,
  setShowBlock: PropTypes.func,
  showReload: PropTypes.bool,
};

export default Message;