import React from "react";
import PropTypes from "prop-types";

/**
 * Returns a text box related to a template placeholder
 * when you write in the textbox, 
 * the placeholder of the template is replaced by the written value
 * @param {string} placeHolderName
 * @param {object} placeholdersValues
 * @param {function} setPlaceholdersValues
 * @returns 
 */
const PlaceHolderItem = ({
    placeHolderName,
    placeholdersValues,
    setPlaceholdersValues }) => {

    /**
     * Updates placeholders values map with values given by user
     * @param {object} event 
     * @param {object} placeHolderName 
     */
    const changeTemplate = (event, placeHolderName) => {
        //  We clone the object without references
        let clonePlaceholdersValues = JSON.parse(JSON.stringify(placeholdersValues));
        clonePlaceholdersValues[placeHolderName] = event.target.value;
        setPlaceholdersValues(clonePlaceholdersValues);
    };

    return (
        <div key={placeHolderName} className="placeHolderDiv">
            <input
                type="text"
                className="form-control placeHolderInput"
                placeholder={placeHolderName}
                value={placeholdersValues[placeHolderName]}
                onChange={(e) => changeTemplate(e, placeHolderName)}
            />
        </div>
    );
};

PlaceHolderItem.propTypes = {
    placeHolderName: PropTypes.string,
    placeholdersValues: PropTypes.object,
    setPlaceholdersValues: PropTypes.func,
};

export default PlaceHolderItem;