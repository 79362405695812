import React from "react";
import PropTypes from "prop-types";
import UserItem from "./UserItem";

/**
 * Search List component to show the list of users
 * @param {Object} param0 
 * :users A list of users
 * :show if the list should be shown or not
 * :setuservalue function to update the user value
 * :keyword the text being search
 */

const SearchUserList = ({ users, show, setuservalue, keyword }) => {

  return (
    <div className={`searchUserList ${show}`}>
      {users &&
        users.slice(0, 5).map((user) => {
          if (user.role === "end-user") {
            return (
              <UserItem
                key={user.id}
                user={user}
                setuservalue={setuservalue}
                keyword={keyword}
              />
            );
          }
          return false;
        })
      }
    </div>
  );
};

SearchUserList.propTypes = {
  users: PropTypes.array,
  show: PropTypes.string,
  setuservalue: PropTypes.func,
  keyword: PropTypes.string
};

export default SearchUserList;