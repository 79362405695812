import axios from "axios";

/**
 * Upload a file to the api, if succes returns a token
 * else return false
 * @param {string} name 
 * @param {string} token 
 * @param {string} xApiKey 
 * @returns 
 */
async function uploadFileToApi(name, token, xApiKey) {
    const URL_API = `https://${sessionStorage.getItem("an")}.execute-api.eu-west-1.amazonaws.com/${sessionStorage.getItem("st")}`;
    const configRequest = {
        method: "post",
        url: `${URL_API}/webview/uploadfile`,
        headers: {
          "Authorization": `Basic ${token}`,
          "x-api-key": xApiKey,
          "Content-Type": "application/json"
        },
        data: JSON.stringify({"attachmentName": name})
      };

    try {
        let response = await axios(configRequest);
        return response.data.message;
    } catch (error) {
        console.log(error);
        return false;
    }
}

/**
 * Generate a presigned URL to upload file
 * else return false
 * @param {string} fileName 
 * @param {string} token 
 * @param {string} apiKey 
 * @returns 
 */
async function presignedURL(fileName, token, apiKey) {
    
    try {
        const URL_API = `https://${sessionStorage.getItem("an")}.execute-api.eu-west-1.amazonaws.com/${sessionStorage.getItem("st")}`;
        const configRequest = {
            method: "get",
            url: `${URL_API}/signed-upload-url?attachmentname=${fileName}`,
            headers: {
                "Authorization": `Basic ${token}`,
                "x-api-key": apiKey
            }
        };
        let response = await axios(configRequest);
        return response.data;
    } catch (error) {
        console.log(error);
        return false;
    }
}

/**
 * Upload a file to the api, if succes returns a token
 * else return false
 * @param {string} url 
 * @param {object} formData 
 * @returns 
 */
async function uploadFileToBucket(url,formData) {

    try {
        const configRequest = {
            method: "POST",
            url: url,
            data: formData
        };
        await axios(configRequest);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export { uploadFileToApi, presignedURL, uploadFileToBucket };